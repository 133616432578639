var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "库存记录",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-collapse', {
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('van-collapse-item', {
    attrs: {
      "title": "筛选",
      "name": "1"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "变更类型",
      "value": _vm._f("typeFilter")(_vm.listQuery.type),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "增加/扣减",
      "value": _vm._f("stockTypeFilter")(_vm.listQuery.stock_type),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showStockTypeActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "库存类型",
      "value": _vm._f("groupFilter")(_vm.listQuery.group),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showGroupActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "开始时间",
      "value": _vm.listQuery.begin_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowDatePicker('begin_date');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "截止时间",
      "value": _vm.listQuery.end_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowDatePicker('end_date');
      }
    }
  }), _c('div', {
    staticClass: "filter-btns"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "type": "warning"
    },
    on: {
      "click": _vm.handleFilterReset
    }
  }, [_vm._v("重置")]), _c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "type": "primary"
    },
    on: {
      "click": _vm.refresh
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1), _c('van-list', {
    ref: "list",
    staticClass: "stock-logs-container",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (stockLog) {
    return _c('div', {
      key: stockLog.id,
      staticClass: "stock-logs-item"
    }, [_c('van-cell', {
      attrs: {
        "title": "变更时间",
        "value": stockLog.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "变更类型",
        "value": _vm._f("typeFilter")(stockLog.type)
      }
    }), _c('van-cell', {
      attrs: {
        "title": "操作人",
        "value": stockLog.employee.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": '变更前库存：' + stockLog.before_stock,
        "value": '变更后库存：' + stockLog.after_stock
      }
    }), _c('van-cell', {
      attrs: {
        "title": "关联内容"
      }
    }, [_c('template', {
      slot: "label"
    }, [_c('div', {
      staticClass: "relation-content"
    }, [stockLog.type === 1 || stockLog.type === 2 ? _c('div', [_vm._v(" 单号【" + _vm._s(stockLog.relation.num) + "】，客户【" + _vm._s(stockLog.relation.client.name) + "】， "), stockLog.before_stock - stockLog.after_stock < 0 ? _c('span', [_vm._v("增加库存【" + _vm._s(stockLog.after_stock - stockLog.before_stock) + "】")]) : _c('span', [_vm._v("扣减库存【" + _vm._s(stockLog.before_stock - stockLog.after_stock) + "】")])]) : stockLog.type === 3 || stockLog.type === 9 ? _c('div', [_vm._v(" 单号【" + _vm._s(stockLog.relation.num) + "】，供应商【" + _vm._s(stockLog.relation.supplier.name) + "】， "), stockLog.before_stock - stockLog.after_stock < 0 ? _c('span', [_vm._v("增加库存【" + _vm._s(stockLog.after_stock - stockLog.before_stock) + "】")]) : _c('span', [_vm._v("扣减库存【" + _vm._s(stockLog.before_stock - stockLog.after_stock) + "】")])]) : stockLog.type === 4 ? _c('div', [_vm._v(" 单号【" + _vm._s(stockLog.relation.num) + "】，【" + _vm._s(stockLog.relation.from_store.name) + "】调往【" + _vm._s(stockLog.relation.to_store.name) + "】， "), stockLog.before_stock - stockLog.after_stock < 0 ? _c('span', [_vm._v("增加库存【" + _vm._s(stockLog.after_stock - stockLog.before_stock) + "】")]) : _c('span', [_vm._v("扣减库存【" + _vm._s(stockLog.before_stock - stockLog.after_stock) + "】")])]) : stockLog.type === 6 || stockLog.type === 7 ? _c('div', [_vm._v(" 单号【" + _vm._s(stockLog.relation.after_goods.after_order.num) + "】，客户【" + _vm._s(stockLog.relation.after_goods.after_order.client.name) + "】， "), stockLog.before_stock - stockLog.after_stock < 0 ? _c('span', [_vm._v("增加库存【" + _vm._s(stockLog.after_stock - stockLog.before_stock) + "】")]) : _c('span', [_vm._v("扣减库存【" + _vm._s(stockLog.before_stock - stockLog.after_stock) + "】")])]) : _c('div', [_vm._v(" - ")])])])], 2)], 1);
  })], 2)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelectAction
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.stockTypeActions
    },
    on: {
      "select": _vm.handleSelectStockTypeAction
    },
    model: {
      value: _vm.showStockTypeActions,
      callback: function callback($$v) {
        _vm.showStockTypeActions = $$v;
      },
      expression: "showStockTypeActions"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.groupActions
    },
    on: {
      "select": _vm.handleSelectGroupAction
    },
    model: {
      value: _vm.showGroupActions,
      callback: function callback($$v) {
        _vm.showGroupActions = $$v;
      },
      expression: "showGroupActions"
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "confirm": _vm.handleDatePickerConfirm,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }