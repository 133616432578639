<template>
  <div>
    <my-nav-bar
      title="库存记录"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item title="筛选" name="1">
            <van-cell title="变更类型" :value="listQuery.type | typeFilter" is-link center @click="showActions = true" />
            <van-cell title="增加/扣减" :value="listQuery.stock_type | stockTypeFilter" is-link center @click="showStockTypeActions = true" />
            <van-cell title="库存类型" :value="listQuery.group | groupFilter" is-link center @click="showGroupActions = true" />
            <van-cell title="开始时间" :value="listQuery.begin_date" is-link center @click="handleShowDatePicker('begin_date')" />
            <van-cell title="截止时间" :value="listQuery.end_date" is-link center @click="handleShowDatePicker('end_date')" />
            <div class="filter-btns">
              <van-button square block type="warning" @click="handleFilterReset">重置</van-button>
              <van-button square block type="primary" @click="refresh">确定</van-button>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-sticky>
      <van-list
        ref="list"
        v-model="loading"
        class="stock-logs-container"
        :finished="finished"
        @load="getList"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div v-for="stockLog in list" :key="stockLog.id" class="stock-logs-item">
          <van-cell title="变更时间" :value="stockLog.created_at" />
          <van-cell title="变更类型" :value="stockLog.type | typeFilter" />
          <van-cell title="操作人" :value="stockLog.employee.name" />
          <van-cell :title="'变更前库存：'+stockLog.before_stock" :value="'变更后库存：'+stockLog.after_stock" />
          <van-cell title="关联内容">
            <template slot="label">
              <!-- 单据 -->
              <div class="relation-content">
                <div v-if="stockLog.type === 1 || stockLog.type === 2">
                  单号【{{ stockLog.relation.num }}】，客户【{{ stockLog.relation.client.name }}】，
                  <span v-if="stockLog.before_stock - stockLog.after_stock < 0">增加库存【{{ stockLog.after_stock - stockLog.before_stock }}】</span>
                  <span v-else>扣减库存【{{ stockLog.before_stock - stockLog.after_stock }}】</span>
                </div>
                <div v-else-if="stockLog.type === 3 || stockLog.type === 9">
                  单号【{{ stockLog.relation.num }}】，供应商【{{ stockLog.relation.supplier.name }}】，
                  <span v-if="stockLog.before_stock - stockLog.after_stock < 0">增加库存【{{ stockLog.after_stock - stockLog.before_stock }}】</span>
                  <span v-else>扣减库存【{{ stockLog.before_stock - stockLog.after_stock }}】</span>
                </div>
                <div v-else-if="stockLog.type === 4">
                  单号【{{ stockLog.relation.num }}】，【{{ stockLog.relation.from_store.name }}】调往【{{ stockLog.relation.to_store.name }}】，
                  <span v-if="stockLog.before_stock - stockLog.after_stock < 0">增加库存【{{ stockLog.after_stock - stockLog.before_stock }}】</span>
                  <span v-else>扣减库存【{{ stockLog.before_stock - stockLog.after_stock }}】</span>
                </div>
                <div v-else-if="stockLog.type === 6 || stockLog.type === 7">
                  单号【{{ stockLog.relation.after_goods.after_order.num }}】，客户【{{ stockLog.relation.after_goods.after_order.client.name }}】，
                  <span v-if="stockLog.before_stock - stockLog.after_stock < 0">增加库存【{{ stockLog.after_stock - stockLog.before_stock }}】</span>
                  <span v-else>扣减库存【{{ stockLog.before_stock - stockLog.after_stock }}】</span>
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>
    </div>

    <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelectAction" />
    <van-action-sheet v-model="showStockTypeActions" :actions="stockTypeActions" @select="handleSelectStockTypeAction" />
    <van-action-sheet v-model="showGroupActions" :actions="groupActions" @select="handleSelectGroupAction" />

    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="handleDatePickerConfirm"
        @cancel="showDatePicker = false"
      />
    </van-popup>

  </div>
</template>

<script>

import myNavBar from '@/components/my-nav-bar'
import { getStockLogs } from '@/api/warehouse_goods'
import { toDateString } from '@/utils'

const typeMaps = {
  0: '全部',
  1: '销售单',
  2: '退货单',
  3: '入库单',
  4: '调货单',
  5: '盘点库存',
  6: '退货差价',
  7: '退货收条',
  8: '门店损耗',
  9: '厂家退货',
  10: '组合商品',
  11: '特惠购'
}
const stockTypeMaps = {
  'add': '增加',
  'sub': '扣减'
}
const groupMaps = {
  1: '账面库存',
  2: '实际库存'
}
export default {
  components: { myNavBar },
  filters: {
    typeFilter(type) {
      return typeMaps[type]
    },
    stockTypeFilter(stockType) {
      return stockTypeMaps[stockType]
    },
    groupFilter(group) {
      return groupMaps[group]
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      showEmpty: false,
      listQuery: {
        goods_id: this.$route.query.goods_id,
        page: 0,
        limit: 10,
        type: 0,
        begin_date: '',
        end_date: '',
        stock_type: '',
        group: 1
      },
      activeName: '',
      list: [],
      showActions: false,
      showStockTypeActions: false,
      showGroupActions: false,
      actions: [
        { name: '全部', value: 0 },
        { name: '销售单', value: 1 },
        { name: '退货单', value: 2 },
        { name: '入库单', value: 3 },
        { name: '调货单', value: 4 },
        { name: '盘点库存', value: 5 },
        { name: '退货差价', value: 6 },
        { name: '退货收条', value: 7 },
        { name: '门店损耗', value: 8 },
        { name: '厂家退货', value: 9 },
        { name: '组合商品', value: 10 },
        { name: '特惠购', value: 11 }
      ],
      stockTypeActions: [
        { name: '增加', value: 'add' },
        { name: '扣减', value: 'sub' }
      ],
      groupActions: [
        { name: '账面库存', value: 1 },
        { name: '实际库存', value: 2 }
      ],
      dateType: '',
      showDatePicker: false,
      currentDate: new Date()
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getStockLogs(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    refresh() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
      this.activeName = ''
    },
    handleSelectAction(action) {
      this.listQuery.type = action.value
      this.showActions = false
    },
    handleSelectStockTypeAction(action) {
      this.listQuery.stock_type = action.value
      this.showStockTypeActions = false
    },
    handleSelectGroupAction(action) {
      this.listQuery.group = action.value
      this.showGroupActions = false
    },
    handleShowDatePicker(type) {
      this.dateType = type
      this.showDatePicker = true
    },
    handleDatePickerConfirm(e) {
      const date = toDateString(e)
      this.listQuery[this.dateType] = date
      this.showDatePicker = false
    },
    handleFilterReset() {
      this.listQuery = {
        goods_id: this.$route.query.goods_id,
        page: 0,
        limit: 10,
        type: 0,
        begin_date: '',
        end_date: '',
        stock_type: '',
        group: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-logs-container {
  padding: 10px 10px 0px 10px;
}
.stock-logs-item {
  margin-bottom: 10px;
}
.relation-content {
  word-break: break-all;
}
.filter-btns {
  display: flex;
}
</style>
